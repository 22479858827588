import {Typography, Hidden, Box, CircularProgress} from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import TermsCondition from "../component/TermsCondition";
import termsAndConditions from "../utils/termsAndConditions.json";
import {useEffect, useState} from "react";

const TermsConditions = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadScript = () => {
      if (!document.getElementById("termly-jssdk")) {
        const script = document.createElement("script");
        script.id = "termly-jssdk";
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;

        script.onload = () => {
          setLoading(false); // Set loading to false once script is loaded
        };

        document.body.appendChild(script);
      } else {
        setLoading(false); // If script is already loaded, set loading to false
      }
    };

    loadScript();

    // Cleanup function to remove the script on unmount
    return () => {
      const existingScript = document.getElementById("termly-jssdk");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);

  return (
    <Box style={{backgroundColor: "#F3F6F6"}}>
      <Header/>

      {/* SECTION 1 */}
      <Box
        sx={{
          textAlign: "center",
          pt: {xs: "30px", md: "40px"},
          pb: {xs: "40px", md: "80px"},
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden mdDown>
            <img src="images/termsConditionsHeader.png" alt="header1"></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: {xs: "40px", md: "65px"},
              lineHeight: {xs: 1.4, md: "75px"},
              ml: {md: 5},
            }}
          >
            Terms & Conditions
          </Typography>
        </Box>

        {/*<Typography
          className="container"
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "26px",
            mt: "17px",
            textAlign: "center",
          }}
        >
          Your attention is particularly drawn to the provisions of clause 4
          (Disclaimers and limitation of Liability) and the indemnity at clause
          12.4
        </Typography>*/}
        <div
          style={{padding: "10px 0px", width:'70%'}}
          name="termly-embed"
          data-id="3e4d0de7-b8e1-4ca5-a231-3c30c3bda335"
        ></div>
      </Box>

      <Footer/>
    </Box>
  );
};

export default TermsConditions;
