import {Typography, Hidden, Box, CircularProgress} from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import privacyPolicy from "../utils/privacyPolicy.json";
import TermsCondition from "../component/TermsCondition";
import {useEffect, useState} from "react";

const PrivacyPolicies = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadScript = () => {
      if (!document.getElementById("termly-jssdk")) {
        const script = document.createElement("script");
        script.id = "termly-jssdk";
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;

        script.onload = () => {
          setLoading(false); // Set loading to false once script is loaded
        };

        document.body.appendChild(script);
      } else {
        setLoading(false); // If script is already loaded, set loading to false
      }
    };

    loadScript();
    return () => {
      const existingScript = document.getElementById("termly-jssdk");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);
  return (
    <Box style={{backgroundColor: "#F3F6F6"}}>
      <Header/>

      {/* SECTION 1 */}
      <Box
        sx={{
          textAlign: "center",
          pt: {xs: "30px", md: "40px"},
          pb: {xs: "40px", md: "80px"},
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden mdDown>
            <img src="images/testimonialHeader1.png" alt="header1"></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: {xs: "40px", md: "65px"},
              lineHeight: {xs: 1.4, md: "75px"},
              ml: {md: 5},
            }}
          >
            Privacy Policy
          </Typography>
        </Box>
        <div style={{
          padding:'10px, 0px',
          width: '70%'
        }} name="termly-embed" data-id="84c4fb7c-f50d-4ddc-9b31-ef378aaaf3a8"></div>

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {loading && <CircularProgress color="inherit" size={50}/>}
        </div>
      </Box>

      <Footer/>
    </Box>
  );
};

export default PrivacyPolicies;
