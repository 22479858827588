import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Chip,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ open, onClose, content }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          backgroundColor: "#F3F6F6",
          border: "1px solid #CFCAD1",
          boxShadow: "0px 10px 30px 0px #00000026",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#F3F6F6" }}>
        <Box sx={{ position: "relative" }}>
          <Chip
            label={content.tag}
            sx={{
              color: "#fff",
              backgroundColor: "rgba(25, 0, 65, 1)",
              fontFamily: "GilroyMedium",
              fontSize: "15px",
              fontWeight: "700",
              lineHeight: "18px",
            }}
          />
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "#C1BAAE",
            }}
            onClick={onClose}
          >
            <CloseIcon sx={{ fontSize: "15px", color: "#0F0F10" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#F3F6F6" }}>
        <Box sx={{ mb: "51px" }}>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "29px",
              color: "#0F0F10",
              pt: "16px",
            }}
          >
            {content.name}
          </Typography>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "22px",
              mt: "4px",
              color: "#0F0F10",
            }}
          >
            {content.title}
          </Typography>
          {content.description.map((item) => {
            return (
              <Typography
                variant="caption"
                sx={{
                  fontFamily: "GilroyMedium",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  color: "#0F0F10",
                  display: "block",
                  mt: "16px",
                }}
              >
                {item}
              </Typography>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
