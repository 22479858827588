import {Typography, Hidden, Box, CircularProgress} from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import DocumentTable from "../component/DocumentView";
import {useEffect, useState} from "react";

const CookiePolicy = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadScript = () => {
      const existingScript = document.getElementById("termly-jssdk");
      if (existingScript) {
        // If the script is already in the document, remove it
        existingScript.remove();
      }

      const script = document.createElement("script");
      script.id = "termly-jssdk";
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;

      script.onload = () => {
        setLoading(false); // Set loading to false once the script is loaded
      };

      document.body.appendChild(script);
    };

    loadScript();

    // Cleanup function to remove the script on unmount
    return () => {
      const existingScript = document.getElementById("termly-jssdk");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);
  return (
    <Box style={{backgroundColor: "#F3F6F6"}}>
      <Header />

      {/* SECTION 1 */}
      <Box
        sx={{
          textAlign: "center",
          pt: { xs: "30px", md: "40px" },
          pb: { xs: "20px", md: "40px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden mdDown>
            <img src="images/termsConditionsHeader.png" alt="header1"></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: { xs: "40px", md: "65px" },
              lineHeight: { xs: 1.4, md: "75px" },
              ml: { md: 5 },
            }}
          >
            Cookie Policy
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {loading && <CircularProgress color="inherit" size={20} />}
        {(
        <div
          name="termly-embed"
          data-id="8df5ca34-6ba4-4048-8a1f-3274f4a3912c"
          style={{
            width:'70%',
            padding: "10px 0px",
          }}
        ></div>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default CookiePolicy;
