import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Box,
  Button,
  Tooltip, Stack,
} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Modal from "./Modal";

const TeamMember = ({
  name,
  title,
  imageUrl,
  bio,
  description,
  tag,
  setContent,
  linkedInUrl
}) => {
  return (
    <Grid item xs={12} sm={3}>
      <Box textAlign="center">
        {/*<Tooltip*/}
        {/*  title={*/}
        {/*    <Typography*/}
        {/*      sx={{*/}
        {/*        overflow: "hidden",*/}
        {/*        textOverflow: "ellipsis",*/}
        {/*        display: "-webkit-box",*/}
        {/*        WebkitLineClamp: 4,*/}
        {/*        WebkitBoxOrient: "vertical",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {description.join(", ")}*/}
        {/*    </Typography>*/}
        {/*  }*/}
        {/*  placement="bottom"*/}
        {/*  arrow*/}
        {/*>*/}
          <Avatar
            alt={name}
            src={imageUrl}
            sx={{
              width: { xs: "80%", md: "100%" },
              height: { xs: "80%", md: "100%" },
              margin: "0 auto",
            }}
          />
        {/*</Tooltip>*/}
        <Typography
            variant="h6"
            sx={{
              marginTop: 2,
              fontFamily: "GilroyMedium",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "24.76px",
              textAlign: "center",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              marginTop:'10px',
              fontFamily: "GilroyMedium",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "21.83px",
              textAlign: "center",
              height:'42px',
              whiteSpace: 'pre-line',
            }}
          >
            {title}
          </Typography>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{
          mt: { xs: 2, md: 3 },}}>
          <LinkedInIcon  sx={{fontSize:'25px'}}/>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "22px",
              textAlign: "center",
              textDecoration: "underline",
              color: "#0F0F10",
              cursor: "pointer",
              display:'flex',
              justifyContent:'center'
            }}
            onClick={() => window.open(linkedInUrl, "_blank")}
          >
            LinkedIn &#8594;
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const teamMembers = [
    {
        name: "Aftharb Mowlana",
        title: "Founder, CEO",
        imageUrl: "images/aboutUs/team3.png",
        description: [
            "Aftharb is a dynamic finance and investments professional with a steadfast commitment to driving sustainable growth. Armed with a master’s degree in Innovation, Entrepreneurship, and Management from Imperial College London, Aftharb’s journey into the world of finance was fueled by a deep-seated passion for entrepreneurship and startups. With a focus on social impact investments across Emerging and Frontier markets in EMEA countries, he has meticulously structured and analysed transactions totaling approximately £1.4 billion, showcasing his prowess in constructing intricate financial models and devising comprehensive business plans.",
            "Aftharb’s multifaceted expertise extends beyond the realm of finance. His entrepreneurial spirit shines through ventures like “Saiyad’s Academy” (formerly known as “ILM Tutors”) which has been in operation for over a decade, teaching students in over 10 countries.",
            "In the healthcare sector, Aftharb’s impact is undeniable. As a Wealth Manager at Growmore Asset Management, Plc, he structured business plans for the construction of healthcare cities within the Middle East and African markets ensuring that the return profiles and ESG metrics were aligned with the sustainable and ethical objectives of the firm as well as the clients. At London Clinic of Medicine and Surgery (LCMS), Aftharb spearheaded efforts to identify new opportunities and promote healthcare services such as telemedicine as well as specialist doctor training programmes. At OakNorth Bank, Aftharb’s contributions as a Lending Support Analyst were instrumental in managing client relationships and enhancing the efficiency of credit underwriting processes, culminating in the development of a cutting-edge CRM system.",
            "Furthermore, his leadership acumen is evident through his roles as Chair of the Student Staff Committee at Imperial College London.",
            "With a proven track record of success and a fervent dedication to making a positive impact, Aftharb is poised to drive innovation and foster positive change in the healthcare sector and beyond.",
        ],
        tag: "Biography",
      linkedInUrl: "https://www.linkedin.com/in/saiyad-aftharb-omar-mowlana-060951105/"
    },
  {
    name: "Amri Shafeek",
    title: "Founder, CPO",
    imageUrl: "images/aboutUs/team1.png",
    description: [
      "Amri Shafeek is a dynamic entrepreneur and technology strategist with an unwavering commitment to fostering innovation across multiple sectors. Hailing from Melbourne, Australia, Amri's academic foundations were laid at Victoria University, where he completed double bachelor’s degrees in human resources and international business. This educational background sparked his deep-rooted passion for entrepreneurship and propelled him into a versatile career.",
      "Amri's professional journey is marked by his profound impact in industries such as renewable energy, healthcare, civil construction, aged care, and technology. He has excelled in various roles including recruitment, debt management, account management, and project management. Amri's adeptness at navigating complex business environments is further evidenced by his expertise in designing web and mobile applications, enhancing operational efficiencies and client engagement across diverse sectors.",
      "In addition to co-founding ClinicalPad, a revolutionary tech venture in the medical field, Amri also established Numuinn Pty Ltd, a venture capital firm focused on digital assets based in Melbourne. His strategic vision for Numuinn Pty Ltd has been instrumental in identifying and nurturing innovative digital solutions, showcasing his capacity to drive substantial growth in the tech landscape.",
      "Amri’s influence extends beyond just business operations. His entrepreneurial ventures demonstrate a commitment to transforming traditional business practices by integrating advanced technologies like AI and machine learning. This approach not only streamlines processes but also significantly improves accuracy and efficiency in clinical settings through platforms like ClinicalPad.",
      "With a proven track record of success and a relentless dedication to pushing the boundaries of technology and entrepreneurship, Amri Shafeek is poised to continue his trajectory of impactful innovations, driving forward industries, and improving professional practices globally.",
    ],
    tag: "Biography",
    linkedInUrl: "https://www.linkedin.com/in/amri-shafeek-2295822a/"
  },
  {
    name: "Omar Mowlana",
    title: "Founder, \n Chief Global Strategist",
    imageUrl: "images/aboutUs/team2.png",
    description: [
      "Omar is an accomplished Industrial Engineer with a strong academic background, holding an MBA and is a Fellow of the Institute of Management Services, UK.",
      "Co-founding Neurosens/ClipicalPad was driven by a vision to revolutionize patient care through advanced technology. His journey continues to evolve, guided by a steadfast commitment to excellence, innovation, and sustainable development on a global scale. In his capacity as the Principal Specialist responsible for Public Sector Leadership Development and Human Resource Management Policy at Commonwealth Headquarters in London, he collaborated with Prime Ministers/ Presidents' offices across 54 Commonwealth nations, working in the regions of Africa, Asia, the Caribbean, the Pacific, North America, and Europe, resulting in impactful reforms aimed at strengthening the public sector.",
      "Starting his career as a Production Planning/Industrial Engineering executive at Sri Lankas premier can manufacturing company, he has consistently demonstrated his ability to drive innovative technology-driven solutions to improve efficiency and productivity in both the public and private sector. During his tenue at the Commonwealth, he conducted annual Pan Commonwealth programmes in collaboration with York University, Canada, and Victoria University, New Zealand to build the capacity of the senior government officials in the Commonwealth countries. Additionally, he facilitated regional and in-country programs in partnership with leading universities and national training institutions to empower public sector officials with the necessary skills and knowledge",
      "Omar's consultancy prowess is well-documented, having undertaken policy studies and projects for esteemed organizations such as the World Health Organisation, the Japan International Cooperation Agency (JICA), the World Bank/International Development Association (WB/IDA), the United Nations Development Programme (UNDP), and the United Nations Economic and Social Commission for Asia and the Pacific (UN-ESCAP), GTZ of Germany among others. His expertise extends to strategic roles such as Industrial Economist at the Ministry of Textiles, where he collaborated with international specialists to elevate the productivity of Sri Lanka's textile/garment sector, a critical contributor to the nation's foreign exchange earnings.",
      "Notably, as the Director-Projects Development at USAID's 'Technology Initiative for the Private Sector' project in Sri Lanka, Omar played a pivotal role in catalysing demand for technical innovation and fostering international partnerships, resulting in remarkable boosts in exports and job creation, surpassing project expectations.",
    ],
    tag: "Biography",
    linkedInUrl: "https://www.linkedin.com/in/saiyad-omar-zain-mowlana-6b096811/"
  },
  {
    name: "Ziali Sivardeen",
    title: "Founder, Medical Lead",
    imageUrl: "images/aboutUs/team4.png",
    description: [
      "Ziali Sivardeen is an Orthopaedic Surgeon who has assessed Elite Level Athletes, Royal Family, and Celebrities during his career. During the London 2012 Olympics, Mr Sivardeen worked at the Olympic Athlete’s Village and had the honour of being selected as the Surgeon who operated on all Olympians and Paralympians that required Shoulder or Elbow Surgery during the Games. Due to his role, Mr Sivardeen was invited to a special reception in Buckingham Palace.",
      "He has held important positions training other surgeons, representing the prestigious Royal Colleges of Surgeons, first as a Tutor from 2010-2014, before being appointed as one of two Regional Surgical Advisors for London. In 2015, he organised the pan London Sports and Exercise Medicine teaching programme, and now is an Examiner for the Prestigious Intercollegiate Fellowship of the Royal Colleges of Surgeons (FRCS exams). As a consultant who spends a fair amount of his time in communicating with GPs and other consultants, his direct inputs were invaluable to develop ClinicalPad.",
      "He has been a member of the Specialist Training Committee for Sports and Exercise Medicine Trainees in London from 2009 to 2015, so played an important part helping to develop this new speciality.",
      "His research interests include orthopaedic shoulder and knee problems, including sports injuries in elite rugby players. At the main World Shoulder and Elbow Meeting (ISCES) in 2010 his research was the only piece from the UK that was short-listed for the best prize, and he was invited to give an educational talk to other surgeons from around world at the next World Shoulder and Elbow Meeting (ISCES) held in Japan in 2013. He is one of the few Orthopaedic Surgeons in the UK trained by 3 Presidents of National Orthopaedic Societies at Fellowship Level and has a passion for teaching and training.  He has run many London Sports Injury Symposia and has been invited around the world to deliver lectures, and master classes teaching other surgeons, consultants, and practitioners.",
    ],
    tag: "Biography",
    linkedInUrl: "https://www.linkedin.com/in/ziali-sivardeen-72442b113/"
  },
  {
    name: "Prof. Mohamed Imam",
    title: "Digital Health & \n Engagement Lead",
    imageUrl: "images/aboutUs/team5.png",
    description: [
      "None"
      ],
    tag: "Biography",
    linkedInUrl: "https://www.linkedin.com/in/mohamed-a-imam-64a0b94a/"
  },
  {
    name: "Prof. Sarath Samarage",
    title: "Engagement Lead, Asia",
    imageUrl: "images/aboutUs/team6.png",
    description: [
      "None"
    ],
    tag: "Biography",
    linkedInUrl: "https://www.linkedin.com/in/sarath-samarage-7a638b18/"
  },
  {
    name: "Ashwinth Ragunathan",
    title: "Systems Administrator",
    imageUrl: "images/aboutUs/team7.png",
    description: [
      "None"
    ],
    tag: "Biography",
    linkedInUrl: "https://www.linkedin.com/in/ashwinth-ragunathan-4224a41a6/"
  },
];

function MeetTeam() {
  const firstRow = teamMembers.slice(0, 4);
  const secondRow = teamMembers.slice(4);
  const [content, setContent] = useState(null);

  return (
    <Box
      sx={{
        padding: { xs: "60px 0px", md: "120px 0px" },
      }}
    >
      <Box className="container">
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
            marginBottom: "24px",
            textAlign: "center",
            // padding: { xs: "0px 24px", md: "0px 386px" },
          }}
        >
          Meet The Team
        </Typography>
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "32px",
            textAlign: "center",
            marginBottom: "60px",
            // padding: { xs: "0px 24px", md: "0px 386px" },
          }}
        >
          We're a rapidly growing diverse team with one thing in common, we're customer obsessed! We come from all comers of the globe &#x1F30D; 🗺️
        </Typography>

        <Box>
          <Grid container spacing={{ xs: 6, md: 5 }} justifyContent="center">
            {firstRow.map((member, index) => (
              <TeamMember key={index} {...member} setContent={setContent} />
            ))}
          </Grid>
          <Box mt={8}>
            <Grid container spacing={{ xs: 6, md: 3 }} justifyContent="center">
              {secondRow.map((member, index) => (
                <TeamMember key={index} {...member} setContent={setContent} />
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {content ? (
        <Modal
          open={!!content}
          onClose={() => setContent(null)}
          content={content}
        />
      ) : null}
    </Box>
  );
}

export default MeetTeam;
